import { useState } from "react";
import PartySocket from "partysocket";
import usePartySocket from "partysocket/react";
import { useQueryState } from "use-location-state"

// We're not using server-side rendering and initial props in this
// example (our app is client-side only), so loadInitialCount is not
// used. But if we were using SSR, we would use this function to load
// the initial count, as returned by onRequest in `party/server.ts`.
export async function loadInitialCount(host: string) {
  const initialCount = await PartySocket.fetch(
    {
      host,
      party: "counter",
      room: "index",
    },
    {
      method: "GET",
    }
  ).then((res) => res.text());
  return parseInt(initialCount) || 0;
}



function DataTable({ data, onRemove, onMove, onUpdate }) {
  const [editState, setEditState] = useState({});

  const handleRemove = index => onRemove && onRemove(index);

  const handleMove = (index, direction) => {
    if (onMove) {
      const to = direction === "up" ? Math.max(0, index - 1) : Math.min(data.length - 1, index + 1);
      onMove(index, to);
    }
  };

  const startEditing = (index, item) => {
    setEditState({ index, ...item });
  };

  const cancelEditing = (e) => {
    setEditState({});
    e.stopPropagation()
  };

  const saveEditing = index => {
    onUpdate(index, { id: editState.id, start: parseFloat(editState.start) || 0, end: parseFloat(editState.end) });
    cancelEditing();
    e.stopPropagation()
  };

  const handleChange = (e, field) => {
    setEditState({ ...editState, [field]: e.target.value });
  };

  const buttonStyle = {
    margin: '0 5px',
    padding: '5px 10px',
    fontSize: '0.8rem',
    background: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const inputStyle = {
    padding: '1px',
    margin: '0 0px',
    fontSize: '0.8rem',
  };

  const cellStyle = {
    border: '1px solid #888',
    padding: '1px',
    fontSize: '0.9rem',
  };

  return (
    <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
      <thead>
        <tr>
          <th style={cellStyle}>ID</th>
          <th style={cellStyle}>Start</th>
          <th style={cellStyle}>End</th>
          <th style={cellStyle}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
        const startEd = () => startEditing(index, item)
        return (
          <tr key={index} onClick={startEd}>
            <td style={cellStyle}>
              {editState.index === index ? (
                <input style={inputStyle} type="text" size={12} value={editState.id} onChange={(e) => handleChange(e, 'id')} />
              ) : (
                item.id
              )}
            </td>
            <td style={cellStyle}>
              {editState.index === index ? (
                <input
                  style={inputStyle}
                  size={4}
                  value={editState.start}
                  onChange={(e) => handleChange(e, 'start')}
                />
              ) : (
                item.start || 0
              )}
            </td>
            <td style={cellStyle}>
              {editState.index === index ? (
                <input
                  style={inputStyle}
                  value={editState.end || ''}
                  size={4}
                  onChange={(e) => handleChange(e, 'end')}
                />
              ) : (
                item.end || ''
              )}
            </td>
            <td style={cellStyle}>
              {editState.index === index ? (
                <>
                  <button style={buttonStyle} onClick={() => saveEditing(index)}>💾</button>
                  <button style={{ ...buttonStyle, background: '#6c757d' }} onClick={cancelEditing}>🚫</button>
                </>
              ) : (
                <>
                  <button style={buttonStyle} onClick={() => handleMove(index, "up")}>⬆️ </button>
                  <button style={buttonStyle} onClick={() => handleMove(index, "down")}>⬇️ </button>
                  <button style={buttonStyle} onClick={() => handleRemove(index)}>🗑️</button>
                  <button style={{ ...buttonStyle, background: '#28a745' }} onClick={startEd}>✏️ </button>
                </>
              )}
            </td>
          </tr>
        )})}
      </tbody>
    </table>
  );
}



export default function Counter() {
  const [count, setCount] = useState({v:[]});
  const [typer, setTyper] = useState("")
  const [viddyMeme, setViddyMeme] = useQueryState('vidmeme', '')

  const socket = usePartySocket({
    // host defaults to the current URL if not set
    //host: process.env.PARTYKIT_HOST,
    room: viddyMeme || 'LOBBY',
    onMessage(evt) {
      setCount(JSON.parse(evt.data));
    },
  });

  const apiUrl = `https://utours.matthewgard1.partykit.dev/parties/main/${viddyMeme}`;
const performApiAction = async (action, bodyData) => {
    const response = await fetch(`${apiUrl}/${action}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    });
    if (!response.ok) {
      throw new Error('API request failed');
    }
  };

  const handleRemove = (index) => {
    performApiAction('rm', { i: index });
  };

  const handleMove = (index, to) => {
    performApiAction('mv', { i: index, to });
  };

  const handleUpdate = (index, changes) => {
    performApiAction('update', { i: index, ...changes });
  };

  const handleCreate = (item) => {
    // Assuming the item contains 'id', 'start', and 'end'
    performApiAction('yt', item);
  };

  const resetRoom = () => {
    setViddyMeme("")
    setCount({v:[]})
    setTyper("")
  };

  const styles = {
    backgroundColor: "#ff0f0f",
    borderRadius: "9999px",
    border: "none",
    color: "white",
    fontSize: "0.95rem",
    cursor: "pointer",
    padding: "1rem 3rem",
    margin: "1rem 0rem",
  };
  let jsonData=[];
try {
  jsonData = count?.v ?? [];
  //jsonData = JSON.parse(count).v;
} catch (error) {
  console.error("Parsing error:", error);
  console.log(count)
  // Handle the error or fallback
}



  if(viddyMeme === "") {
    return (
    <>
    <p>what tour you makin today?</p>
    <input type="text" value={typer} onChange={e => setTyper(e.target.value)} />
    <button style={styles} onClick={()=>resetRoom && setViddyMeme(typer)}> GO </button>
    </>)
  }

  let lastRow = jsonData[jsonData.length-1]
  return (
<>

    <button style={styles} onClick={resetRoom}>X</button>
    <input type="text" value={viddyMeme} onChange={e => setViddyMeme(e.target.value)} />
    <a href={`https://utours.matthewgard1.partykit.dev/parties/main/${viddyMeme}/tour`} style={{float:"right"}}>.tour</a>
    <p>{JSON.stringify(jsonData)}</p>
    <DataTable data={jsonData} onRemove={handleRemove} onMove={handleMove} onUpdate={handleUpdate} />
    <button onClick={() =>{handleCreate({})} }>➕</button>
    <button onClick={() =>{handleCreate({id:lastRow.id, start:lastRow.end||0})} }>++</button>
    <button onClick={() =>{handleCreate({...lastRow})} }>🔂</button>
</>
  );
}
